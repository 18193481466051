a {
  color: #f50057;
}

a .logo {
  color: white;
}

.App {
  text-align: center;
}

.paper {
  margin: 6rem 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  width: 500px;
  margin: 0.5rem;
}

.card__full {
  width: 800px;
  margin: 0.5rem;
}

.ad__details .ad__text {
  margin-bottom: 16px;
  margin-top: 16px;
}

.paper .ad__details {
  display: block;
}

.ad__details .button-wrapper {
  display: flex;
  justify-content: space-around;
}

.card .category__headline,
.card .category__subtext,
.card__full .category__headline,
.card__full .category__subtext {
  display: inline-block;
}

.card .category__subtext,
.card__full .category__subtext {
  margin-left: 0.25rem;
}

.cities {
  margin-top: 16px;
  margin-bottom: 16px;
}

.cities__chip {
  margin-right: 4px;
  margin-bottom: 4px;
}
